import React, { useEffect, useState } from 'react'
import { motion, useViewportScroll, useTransform } from 'framer-motion'
import { debounce } from 'throttle-debounce'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import useMotionNavOffsetStyle from 'src/common/hooks/use-motion-nav-offset-style'

type ArthurProps = {
  feedLength: number
}
const ArthurFramer = ({ feedLength }: ArthurProps) => {
  const flippedProps = useMotionNavOffsetStyle({}, { dodge: 'none' })
  const props = useMotionNavOffsetStyle({ dodge: 'none' })
  const [scrollPer, setScrollPer] = useState(0)
  const arthurFluidData = useArthurFluidData()

  useEffect(() => {
    let scrollY = 0
    let ticking = false

    const updatePosition = (pos: number) => {
      const totalScrollHeight = document.body.clientHeight - window.innerHeight
      const percent = (pos / totalScrollHeight) * 100
      const boundedPercent = percent > 100 ? 100 : percent < 0 ? 0 : percent
      setScrollPer(boundedPercent)
    }

    const handleScroll = debounce(150, () => {
      scrollY = window.scrollY
      if (!ticking) {
        window.requestAnimationFrame(() => {
          updatePosition(scrollY)
          ticking = false
        })
        ticking = true
      }
    })

    //call to allow Arthur to update when more feed items are loaded
    handleScroll()

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [feedLength])

  const transition = {
    type: 'spring',
    damping: 15,
    stiffness: 280,
  }

  return (
    <>
      <FlippedArthurWrapper {...flippedProps}>
        <ArthurSpringWrapper
          initial={false}
          animate={{ y: `${(100 - scrollPer) * 0.84}%` }}
          transition={transition}
        >
          <ArthurImg Tag="div" fluid={arthurFluidData as any} />
        </ArthurSpringWrapper>
      </FlippedArthurWrapper>
      <ArthurWrapper {...props}>
        <ArthurSpringWrapper
          initial={false}
          animate={{ y: `${(100 - scrollPer) * 0.84}%` }}
          transition={transition}
        >
          <ArthurImg Tag="div" fluid={arthurFluidData as any} />
        </ArthurSpringWrapper>
      </ArthurWrapper>
    </>
  )
}

export default ArthurFramer

const ArthurWrapper = styled(motion.div)`
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 90vmin;
  z-index: -99999;
`
const FlippedArthurWrapper = styled(ArthurWrapper)`
  top: 0;
  transform: rotate(180deg);
  display: none;
  ${(p) => p.theme.breakpoints.up('md')} {
    display: initial;
  }
`
const ArthurSpringWrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
`
const ArthurImg = styled(BackgroundImage)`
  width: 100%;
  height: 100%;
  background-position: bottom right;
  background-size: contain;
`
const useArthurFluidData = () => {
  const { allFile } = useStaticQuery<any>(graphql`
    query ArthurFramer {
      allFile(
        filter: {
          relativePath: { eq: "arthur/Arthur.png" }
          sourceInstanceName: { eq: "images" }
        }
      ) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              originalName
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `)

  return allFile.nodes[0].childImageSharp?.fluid
}
