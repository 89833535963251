import { useState, useEffect, useContext } from 'react'
import { throttle } from 'throttle-debounce'
import firebase from 'gatsby-plugin-firebase'
import { FeedItem } from './types'
import useTheme from 'src/common/hooks/use-theme'
import useStaticNewsData from 'src/features/news/use-static-news-data'
import useDynamicNewsData from './use-dynamic-news-data'
import { NewNewsContext } from './new-news-context'

export type FeedState = {
  isLoading: boolean
  loadedAllStatic: boolean
  loadedAllDynamic: boolean
  feedItems: FeedItem[]
  numberOfNew: number
  currentEndDate: Date
}

const useHybridNewsData = () => {
  const [newNews, setNewNews] = useContext(NewNewsContext)
  const staticFeedItems = useStaticNewsData()
  const theme = useTheme()
  const isSmUp =
    typeof window !== 'undefined'
      ? window.innerWidth >= theme.breakpoints.values.sm
      : true
  //Ideally use a number that divides static query limit (current: 60)
  const limit = isSmUp ? 20 : 12
  const initialStatic = staticFeedItems.slice(0, limit)
  const initialFeedItems = newNews
    ? newNews.feedItems.concat(initialStatic)
    : initialStatic
  const [feedState, setFeedState] = useState<FeedState>({
    isLoading: !newNews,
    loadedAllStatic: false,
    loadedAllDynamic: false,
    feedItems: initialFeedItems,
    numberOfNew: 0,
    currentEndDate: staticFeedItems[staticFeedItems.length - 1].date,
  })
  const dynamicFeedState = useDynamicNewsData(feedState, setFeedState)

  //load more static articles on scrolling to bottom
  useEffect(() => {
    const handleScroll = throttle(60, () => {
      const totalScrollHeight = document.body.clientHeight - window.innerHeight
      const scrollDistanceFromBottom = Math.floor(
        totalScrollHeight - window.scrollY
      )
      const skeletonCardHeightPx = 60
      if (
        scrollDistanceFromBottom <= skeletonCardHeightPx * 2 &&
        !feedState.loadedAllStatic
      ) {
        setFeedState((prevState) => {
          const oldFeedLength = prevState.feedItems.length
          const staticLength = staticFeedItems.length
          const sliceLocation = oldFeedLength - prevState.numberOfNew
          const newItems = prevState.feedItems.concat(
            staticFeedItems.slice(sliceLocation, sliceLocation + limit)
          )
          return {
            ...prevState,
            loadedAllStatic:
              newItems.length >= staticLength + prevState.numberOfNew,
            scrollPercent: scrollDistanceFromBottom,
            feedItems: newItems,
          }
        })
      }
    })
    //call once for tall devices in case they can't scroll
    handleScroll()

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchNewestArticles = async () => {
    if (typeof window !== 'undefined') {
      const newsCol = firebase.firestore().collection('news')
      const newest = await newsCol
        .where('date', '>', staticFeedItems[0].date)
        .orderBy('date', 'desc')
        .get()

      const newDocs = newest.docs.map((doc) => {
        const data = doc.data()
        return { ...data, date: new Date(data.date.seconds * 1000) }
      }) as FeedItem[]
      setFeedState((prevState) => {
        prevState.feedItems.unshift(...newDocs)
        return {
          ...prevState,
          numberOfNew: newDocs.length,
          isLoading: false,
          feedState: prevState.feedItems,
        }
      })
      setNewNews({ feedItems: newDocs, prevUpdate: new Date() })
    }
  }

  //control fetching new data from firestore
  useEffect(() => {
    //always look for new articles if no news state has been stored in context yet
    if (newNews === undefined) {
      fetchNewestArticles()
    }
    //if new articles haven't been fetched since the last time they may have
    //been updated in firestore, try fetching again on mount
    if (newNews) {
      const now = new Date()
      const updateInterval = 30
      const fstoreUpdateMins = []
      for (let i = 5; i < 60; i = i + updateInterval) {
        fstoreUpdateMins.push(i)
      }
      const prevIntervalMin = Math.max(
        ...fstoreUpdateMins.filter((value) => value <= now.getMinutes())
      )
      const prevFstoreUpdate = new Date()
      //need to account for being below 5 past the hour
      if (prevIntervalMin >= 0) {
        prevFstoreUpdate.setMinutes(prevIntervalMin)
      } else {
        prevFstoreUpdate.setHours(prevFstoreUpdate.getHours() - 1)
        prevFstoreUpdate.setMinutes(
          fstoreUpdateMins[fstoreUpdateMins.length - 1]
        )
      }
      prevFstoreUpdate.setSeconds(0)

      if (newNews.prevUpdate < prevFstoreUpdate) {
        setFeedState((feedState) => ({ ...feedState, isLoading: true }))
        fetchNewestArticles()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { ...feedState, loadMore: dynamicFeedState.loadMore }
}

export default useHybridNewsData
