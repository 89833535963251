import React, { useEffect, useContext } from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { PageProps } from 'gatsby'
import { LayoutContext } from 'src/features/layout'
import SEO from 'src/common/seo'
import NewsItem from 'src/features/news/news-item'
import useMotionNavOffsetStyle from 'src/common/hooks/use-motion-nav-offset-style'
import ArthurFramer from 'src/features/news/arthur-framer'
import { GridList, dodgeFab } from 'src/common/styles'
import LoadMoreButton from 'src/features/news/load-more-button'
import useHybridNewsData from 'src/features/news/use-hybrid-news-data'
import NewsItemSkeleton from 'src/features/news/news-item-skeleton'
import ScrollToTopFab from 'src/features/scroll-to-top-fab'

const News = ({ location }: PageProps) => {
  const props = useMotionNavOffsetStyle({}, { cssProp: 'padding' })
  const { setLocation } = useContext(LayoutContext)
  const {
    isLoading,
    loadedAllStatic,
    loadedAllDynamic,
    feedItems,
    loadMore,
  } = useHybridNewsData()

  useEffect(() => {
    setLocation(location)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <SEO title="News" />
      <ArthurFramer feedLength={feedItems.length} />
      <Container {...props}>
        <GridList>
          {isLoading && NewsItemSkeleton()}
          {isLoading && NewsItemSkeleton()}
          {feedItems.map((feedItem) => (
            <NewsItem newsEntry={feedItem} key={feedItem.id} />
          ))}
          {loadedAllStatic ? null : NewsItemSkeleton()}
          {loadedAllStatic ? null : NewsItemSkeleton()}
        </GridList>
        {loadedAllStatic && !loadedAllDynamic && (
          <LoadMoreButton isLoading={isLoading} loadMore={loadMore} />
        )}
      </Container>
      <ScrollToTopFab />
    </>
  )
}

export default News

const Container = styled(motion.div)`
  ${(p) =>
    dodgeFab({
      contentWidth: p.theme.breakpoints.values.md,
      cssProp: 'margin',
    })}
`
