import Paper from '@material-ui/core/Paper'
import styled from 'styled-components'

export const NewsCard = styled(Paper)`
  display: grid;
  grid-template-columns: 33% auto;
  grid-gap: ${(p) => p.theme.spacing(1)}px;
  background-color: ${(p) => p.theme.palette.background.paper}cc;
  height: 100%;
  padding: ${(p) => p.theme.spacing(1)}px;
  :hover .hover-underline {
    text-decoration: underline;
  }
`
