import React from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import LoadingSpinner from 'src/common/components/loading-spinner'
import { absCenterX } from 'src/common/styles'

export type LoadMoreButtonProps = {
  isLoading: boolean
  loadMore(): void
}
const LoadMoreButton = ({ isLoading, loadMore }: LoadMoreButtonProps) => (
  <LoadMoreContainer>
    <LoadButton
      onClick={loadMore}
      disabled={isLoading}
      variant="contained"
      color="primary"
    >
      load more
    </LoadButton>
    <SpinnerWrapper>
      <LoadingSpinner isLoading={isLoading} size={24} />
    </SpinnerWrapper>
  </LoadMoreContainer>
)

export default LoadMoreButton

const LoadMoreContainer = styled.div`
  position: relative;
  padding: 0 ${(p) => p.theme.spacing(1)}px;
  margin: auto;
  margin-top: ${(p) => p.theme.spacing(1)}px;
  margin-bottom: ${(p) => p.theme.spacing(2)}px;
  text-align: center;
  max-width: ${(p) => p.theme.breakpoints.values.md + p.theme.spacing(2)}px;
  ${(p) => p.theme.breakpoints.up('sm')} {
    padding: 0 ${(p) => p.theme.spacing(2)}px;
  }
`
const LoadButton = styled(Button)`
  width: 100%;
`
const SpinnerWrapper = styled.div`
  position: absolute;
  top: 0;
  ${absCenterX}
  height: 36px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
