import firebase from 'gatsby-plugin-firebase'
import { FeedState } from './use-hybrid-news-data'
import { SetState } from 'src/common/types'

type Doc = firebase.firestore.QueryDocumentSnapshot<
  firebase.firestore.DocumentData
>
const useDynamicNewsData = (
  feedState: FeedState,
  setFeedState: SetState<FeedState>,
  limit = 20
) => {
  const { isLoading, feedItems } = feedState

  const fetchArticles = async () => {
    const newsCol = firebase.firestore().collection('news')
    let newFeedItems: any[] = []
    const cached = await newsCol
      .where('date', '<', feedState.currentEndDate)
      .orderBy('date', 'desc')
      .limit(limit)
      .get({ source: 'cache' })

    console.log(
      'fetchArticles -> feedState.currentEndDate',
      feedState.currentEndDate
    )
    const cachedLength = cached.docs.length
    const shouldFetchOlderFromServer = cachedLength < limit
    console.log(
      'fetchArticles -> shouldFetchOlderFromServer',
      shouldFetchOlderFromServer
    )

    const lastCachedDate = new Date(
      (cached.docs[cachedLength - 1]?.data().date.seconds ??
        feedState.currentEndDate.valueOf() / 1000) * 1000
    )
    const stuffAfterCachedUpToLimit =
      shouldFetchOlderFromServer &&
      (await newsCol
        .where('date', '<', lastCachedDate)
        .orderBy('date', 'desc')
        .limit(limit - cachedLength)
        .get())

    const pushFeedItems = (doc: Doc) => {
      const data = doc.data()
      const date = new Date(data.date.seconds * 1000)
      newFeedItems.push({ ...data, date })
    }
    cached.docs.forEach(pushFeedItems)
    if (stuffAfterCachedUpToLimit) {
      stuffAfterCachedUpToLimit.forEach(pushFeedItems)
    }

    setFeedState((feedState) => {
      newFeedItems.sort((a, b) => b.date.valueOf() - a.date.valueOf())

      const currentEndDate = newFeedItems.length
        ? newFeedItems[newFeedItems.length - 1]?.date
        : feedState.currentEndDate

      return {
        ...feedState,
        currentEndDate,
        isLoading: false,
        feedItems: feedState.feedItems.concat(newFeedItems),
        loadedAllDynamic: newFeedItems.length < limit,
      }
    })
  }

  const loadMore = () => {
    setFeedState((feedState) => ({ ...feedState, isLoading: true }))
    fetchArticles()
  }

  return { isLoading, feedItems, loadMore }
}

export default useDynamicNewsData
