import React from 'react'
import styled from 'styled-components'
import Skeleton from '@material-ui/lab/Skeleton'
import { NewsCard } from './styles'
import { rounded } from 'src/common/styles'

const NewsItemSkeleton = () => (
  <NewsCard>
    <SkeletonThumbnail animation="wave" height="100%" variant="rect" />
    <div>
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
      <Skeleton animation="wave" width={'60%'} />
    </div>
  </NewsCard>
)

export default NewsItemSkeleton

const SkeletonThumbnail = styled(Skeleton)`
  ${rounded}
`
