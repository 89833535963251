import React from 'react'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import Fab from 'src/common/components/fab'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'

const ScrollToTopFab = () => {
  const triggered = useScrollTrigger({
    threshold: 600,
    disableHysteresis: true,
  })

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <Fab isIn={triggered} onClick={handleClick}>
      <ExpandLessIcon />
    </Fab>
  )
}

export default ScrollToTopFab
