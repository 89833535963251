import { useStaticQuery, graphql } from 'gatsby'
import { StaticNewsDataQuery } from '../../../types/graphql-types'
import { FeedItem } from './types'

const useStaticNewsData = () => {
  const query = useStaticQuery<StaticNewsDataQuery>(graphql`
    query StaticNewsData {
      allFirestoreNews(sort: { fields: date, order: DESC }, limit: 60) {
        nodes {
          title
          date
          photoUrl
          source
          url
          id
        }
      }
    }
  `)

  //not sure how I feel about the typing, but oh well
  const feedItems = query.allFirestoreNews.nodes.map((item) => ({
    ...item,
    date: new Date(item.date),
  })) as FeedItem[]

  return feedItems
}

export default useStaticNewsData
